import React from "react"

import "./impressum.styl"

export default () => (
  <div id="impressum" className="main-page">
    <h1 className="page-title">
      Impressum
      <div className="uk-grid">
        <div className="uk-width-2-2">
          <h3>
            Apotheke Loosdorf, Mag. pharm. Cathrin Taufner-Stich KG
            <br />
            Frau Mag. Cathrin Taufner-Stich
            <p>
            Wiener Straße 12
            <br />
              <br />
              3382 Loosdorf
              <br />
              Österreich
              <br />
              <br />
              Tel.: <a href='tel:+4327546331'>+43 27 54/63 31</a>
              <br />
              Fax: +43 27 54/63 31-9
              <br />
              <a href="mailto:office@apotheke-loosdorf.at">office@apotheke-loosdorf.at</a><br />
            </p>
          </h3>
          <h3>
          Informationen lt. ECG
            <p>
            UID Nummer: ATU79067135 <br /> <br />
            Firmenbuch Nummer: FN 597542 p <br />
            Firmenbuchgericht: HG NÖ <br />
            Zuständige Aufsichtsbehörde: BM für soziale Sicherheit und Generationen <br />
            „Das Unternehmen ist eine öffentliche Apotheke nach 
            österreichischem Apothekengesetz. Sein Leiter ist Mitglied 
            der Österreichischen Apothekerkammer.“<br />
            Berufsrechtliche Vorschrift: Apothekengesetz <br />
            Weitere Informationen finden Sie auf <a href="https://www.apotheker.or.at">www.apotheker.or.at</a>  
            unter der Rubrik Apothekerkammer/Themenbereiche/Information 
            der Rechtsabteilung/Apothekenrelevante Gesetze und Verordnungen. 
            </p>
          </h3>
        </div>
      </div>
    </h1>
  </div>
)
